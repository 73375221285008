// =============================================================================
//
//  Typography Base Variables
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

$html__font-size: 10px;

$a__color: $black;
$a__text-decoration-color: $black;
$a__transition: all 0.3s ease;

$a-hover__color: $grey-two;
$a-hover__text-decoration-color: $grey-two;

$h1__font-weight: $bold;
$h1__line-height: 1.08;
$h1__letter-spacing: -0.02rem;

$h2__font-weight: $bold;
$h2__line-height: 1.08;
$h2__letter-spacing: -0.02rem;

$h3__font-weight: $bold;
$h3__line-height: 1;
$h3__letter-spacing: -0.017rem;

$campaign-h1__font-weight: $bold;
$campaign-h1__line-height: 1.06;

$campaign-h2__font-weight: $bold;
$campaign-h2__line-height: 1.08;

$campaign-h3__font-weight: $bold;
$campaign-h3__line-height: 1.11;

$button__padding: ($base-padding * 0.75) ($base-padding);
$button__background-color: $dark-blue;
$button__color: $white;
$button__transition: background-color 0.3s ease;
$button-hover__background-color: $greyish-brown;

$button-yellow__background-color: $black;
$button-yellow__color: $black;

$button-yellow-hover__background-color: $dark-blue;

$button-ghost__padding: ($base-padding * 1.1) ($base-padding * 1.5);
$button-ghost__border: $black solid $border;
$button-ghost__border-radius: 0.3rem;
$button-ghost__color: $black;
$button-ghost__font-weight: $normal-weight;

$button-ghost-hover__border-color: $black;
$button-ghost-hover__color: $black;

$p__line-height: 1.4;

$b__font-weight: $bold;

// =============================================================================
//  Large Screen
// =============================================================================

$h1__line-height--large: 1.09;
$h1__letter-spacing--large: -0.05rem;

$h2__line-height--large: 1.11;
$h2__letter-spacing--large: -0.05rem;

$h3__line-height--large: 1.15;
$h3__letter-spacing--large: -0.02rem;

$button__padding--large: ($base-padding * 2.05) ($base-padding * 4.5);

$button-small__padding--large: ($base-padding * 0.9) ($base-padding * 2.2);

$button-black__background-color--large: $black;
